/* Colour palette */


/* Old palette 
$primary-red: #AD2B2B;
$primary-green: #5ED8BC;
$primary-white: white;
$primary-black: black;
$primary-red-bright: #BA2026;
$primary-red-dark: #851537;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #78A1BB; 
 */

$primary-red-old: #AD2B2B;
 
$primary-red: #ea2952;
$primary-green: #00D4FF;
$primary-white: white;
$primary-black: #14223D;
$primary-red-bright: #FF2E92;
$primary-red-dark: #ea2952;

$secondary-dark-grey: #474448;
$secondary-grey: grey;
$secondary-light-grey: lightgrey;
$secondary-blue: #7b39ED;  

$window-background-colour: #f0f0f0;
$content-background-colour: white;

@mixin card {
//  box-shadow: 0px 1px 0px 0px rgba(0,0,0,.35);
  transition: 0.1s;
  margin-bottom: 5px;
  border-radius: 3px;
  border-collapse: collapse;
  padding: 0px;
  background: $primary-white;
  width: 100%;


  border: 1px solid #EFF3F8;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px #E0E3E9;
}

.cardRowPlusIcon {
  position: absolute;
  text-align: center;
  line-height: 28px;
  padding-top: 2px;
  padding-left: 1px;
  top: 7px;
  left: 10px;
  width: 28px;
  height: 28px;
  background-color:#14223D;
  border-radius: 14px;
}

.cardRowIcon {
  position: absolute;
  text-align: center;
  top: 5px;
  left: 9px;
  width: 28px;
  height: 28px;
  color: $secondary-dark-grey;
}

.cardRowDeleteIcon {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 28px;
  height: 28px;
  color: $secondary-dark-grey;
}

.cardRowText {
  position: absolute;
  top: 10px;
  left: 45px;
  width: 300px;
  height: 25px;
}

@mixin hover-effect {
  filter: brightness(86%);
}

@mixin card-hover {
  @include hover-effect;
}

@mixin card-placeholder {
  box-shadow: 0px 1px 0px 0px rgba(0,0,0,0);
  transition: 0.3s;
  margin-bottom: 5px;
  border-collapse: collapse;
  padding: 0px;
  margin-bottom: 10px;

  background: $secondary-light-grey;
 // background: $primary-white;
  overflow: hidden;
}

.cardRow {
  position: relative;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid $secondary-light-grey;
  background: $primary-white;
}

.cardRow:hover {
  @include hover-effect;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  color: $primary-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $window-background-colour;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  color: $primary-red;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  position: relative;
  background-image: linear-gradient(to bottom right,$primary-red-dark,$primary-red-dark);

  height : 50px;
  display: flex;
  align-items: center;
  justify-content:center;
  text-transform: capitalize; 
  overflow: hidden;
}

.headerIcon {
  position: relative;
  height: 50px;
}

.headerIconContainer {
  position: absolute;
  margin: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  text-align: center;
}

.headerTitle {
  // background-image: linear-gradient(to bottom right,$primary-red-dark, $primary-red-dark);
  //background-image: linear-gradient(to bottom right,$primary-red-old, $primary-red-old);

  background: white;
  color: black;
  
  height : 50px;
  text-align: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid $secondary-light-grey;
}

.navTitle {
  color: $primary-black;
  text-align: center;
  margin-left: 0px;
  width: 100%;
  font-size: 20px;
  position: relative;
  top: 13px;
}

@font-face {font-family: "DINSchrift";
  src: url("https://db.onlinewebfonts.com/t/ed4e8ed29e58a2dcc9a8a8bad46e2d29.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/ed4e8ed29e58a2dcc9a8a8bad46e2d29.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("https://db.onlinewebfonts.com/t/ed4e8ed29e58a2dcc9a8a8bad46e2d29.woff2") format("woff2"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/ed4e8ed29e58a2dcc9a8a8bad46e2d29.woff") format("woff"), /* chrome firefox */
  url("https://db.onlinewebfonts.com/t/ed4e8ed29e58a2dcc9a8a8bad46e2d29.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("https://db.onlinewebfonts.com/t/ed4e8ed29e58a2dcc9a8a8bad46e2d29.svg#DINSchrift") format("svg"); /* iOS 4.1- */
}
/* 
  @import url(//db.onlinewebfonts.com/c/d5e638ad62a611f703a8e0e951853da0?family=Herculanum);

   @import url(//db.onlinewebfonts.com/c/390289fedd068780e28c0f9f259c3782?family=ITC+Bauhaus);
   @import url(//db.onlinewebfonts.com/c/0c453315c27507762e6b9536cfea76a6?family=Frutiger);
   @import url(//db.onlinewebfonts.com/c/baf7b1055484a2eb9215b5e296843efd?family=Neo+Sans+Pro);
   @import url(//db.onlinewebfonts.com/c/c424d9944d371a3d5d2e38222fa0c299?family=Foco);
 */

.curvesImage {
  position: absolute; 
  top: 55px;
  z-index: 0;
}

.logo {
  position: relative;
  color: #E5E5E5;
  font-size: 170%;
  vertical-align: middle;
  font-family: "DINSchrift";
  height: 50px;
  margin: auto;
  width: 300px;
}

.logo-mark {
  position: absolute;
  bottom: 14px;
  left: 106px;
  width: 87px;
  height: 22px;
}

h1 {
  font-size: 130%;
  color: $secondary-dark-grey;
}

.btn,.btn-large,.btn-medium {
  /* default for <button>, but useful for <a> */

  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;

  height: 44px;
  border: solid 1px transparent;
  border-radius: 18px;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;

  outline: none;
}

.btn-large {
  width: 100%;
}

.btn-small {
  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  /* default for <button>, but useful for <a> */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  border: solid 1px transparent;
  border-radius: 10px;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;
  outline: none;
}

.radioButtonPressed, .radioButtonNotPressed {
  color: $primary-white;
  /*  background-color: $secondary-grey; */
  background-color: $secondary-grey;

  /* default for <button>, but useful for <a> */
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  border: solid 1px transparent;
  border-radius: 10px;
  width: 100%;

  /* create a small space when buttons wrap on 2 lines */
  margin: 2px 0;

  /* size comes from text & padding (no width/height) */
  padding: 0.5em 1em;
  outline: none;
}

.radioButtonPressed {
  background-color: $primary-red;
  border: 2px solid  $primary-red;

}

.radioButtonNotPressed {
  background-color: $primary-white;
  color: $secondary-grey;
  border: 2px solid  $secondary-grey;
}

/* Firefox: removes the inner border shown on focus */
.btn::-moz-focus-inner {
  border: none;
}

/* old-school "down" effect on clic + color tweak */
.btn:active {
  transform: translateY(1px);
  filter: saturate(150%);
}

.btn-primary {
  /* make sure colors have enough contrast! */
  color: $primary-white;
/*  background-color: $secondary-grey; */
  background-color: $secondary-blue;

}

/* inverse colors on mouse-over and focus */
.btn:hover,
.btn:focus,
.btn-small:hover,
.btn-small:focus,
.btn-primary:hover,
.btn-primary:focus {
  @include hover-effect;
}

.btn-google {
  /* make sure colors have enough contrast! */
  color: #000000;
  background-color: $primary-white;
  outline: none;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  border-radius: 18px;
  padding-top: 12px;
}

.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0px 1px 0px 0px rgba(0,0,0,.35);
  transition: 0.3s;
  margin-bottom: 5px;
  border-radius: 3px;
  border-collapse: collapse;
  padding: 0px;
  background: $primary-white;
}

.cardTitle {
  font-size: 110%;
  font-weight: medium;
}

.bottomMargin {
  margin-bottom: 20px;
}

.taskCard {
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
}

.loginTroubleLoggingIn {
  text-align: center;
}

.progressBorder {
    border:1px solid #ccc; 
    background-color: #C4C4C4;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.progressBar,.progressBar:hover,.progressBar,.progressBar:hover{
  color:#000!important;
  background-color:#78A1BB;
  border-radius: 5px;
  height: 8px 
}

.loading {
  padding: 5%;
}

.cardStatus {
  font-size: 80%;
  color: #696969;
  font-weight: medium;
}


/* Create three equal columns that floats next to each other */
.largeColumn {
  float: left;
  width: 66%;
  padding: 10px;
}

.smallColumn {
  float: left;
  width: 34%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.right {
  text-align: right;
}

form {
  padding: 5%;
}

.form-label {
  font-size: 100%;
  margin-top: 10px;

  margin-bottom: 10px;
}

.pinnedCardContainer {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: 70px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.pinnedCard {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFF6E0;
  border-radius: 10px;
}

.pinnedCardPin {
  position: absolute;
  left: 8px;
  top: 10px;
  color: white;
  font-size: 23px;
}

.pinnedCardPinCircle {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
  background: $secondary-blue;
  font-size: 23px;
  border-radius: 20px;
}

.pinnedCardDescription {
  position: absolute;
  top: 13px;
  left: 70px;
  width: 200px;
  height: 44px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}


.pinnedCardButton {
  position: absolute;
  right: 10px;
  top: 20px;
}

.reloadDataCardContainer {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.reloadDataCardArrow {
  width: 100%;
  text-align: center;
  font-size: 80px;
  line-height: 100px;
  height: 100px;
  color: $secondary-dark-grey;
}

.reloadDataCard {
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $window-background-colour;
}

.reloadDataCardMessage {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 30px;
  text-align: center;
  color: $secondary-dark-grey;

}

.reloadDataCardButton {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.reloadDataCardReloadIcon {
  width: 30px;
  height: 30px;
  color: black;
}


.lumosTouchpointCardContainer {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: 70px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.lumosTouchpointCard {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFF6E0;
  border-radius: 10px;
}

.lumosTouchpointCardAvatar {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 23px;
}

.lumosTouchpointCardDescription {
  position: absolute;
  top: 13px;
  left: 70px;
  width: 180px;
  height: 44px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.lumosTouchpointCardDescriptionNoButton {
  position: absolute;
  top: 13px;
  left: 70px;
  width: 290px;
  height: 44px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}


.lumosTouchpointCardButton {
  position: absolute;
  right: 0px;
  top: 20px;
}


.addHabitCardContainer {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  height: 70px;

  margin-top: 10px;
  margin-bottom: 10px;
}

.addHabitCard {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #FFF6E0;
  border-radius: 10px;
}

.addHabitCardPin {
  position: absolute;
  left: 12px;
  top: 24px;
  font-size: 23px;
}

.addHabitCardDescription {
  position: absolute;
  top: 13px;
  left: 46px;
  width: 210px;
  height: 44px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.addHabitCardButton {
  position: absolute;
  right: 10px;
  top: 20px;
}

.avatarLarge {
  float: left;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 45px;
  text-align: center;;
  background-color: $secondary-blue;
  line-height: 100px;
  margin-right: 5px;
}

.avatar {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 15px;
  text-align: center;;
  background-color: $secondary-grey;
  line-height: 32px;
  margin-right: 5px;
}

.avatarSmall {
  float: left;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: $primary-white;
  font-size: 8px;
  text-align: center;;
  background-color: $secondary-dark-grey;
  line-height: 28px;
  margin-right: 3px;
}

.avatarImage {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.avatarBadge {
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: $secondary-dark-grey;
  font-weight: 600;
  font-size: 10px;
  border-radius: 35px;
  height: 14px;
  line-height: 14px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: $secondary-light-grey;
}

.avatarPile {
  position: relative;
  height: 28px;
  width: 70px;
}

.avatarPileSlot1 {
  position: absolute;
  right: 42px;
  z-index: 1;
}

.avatarPileSlot2 {
  position: absolute;
  right: 28px;
  z-index: 2;
}

.avatarPileSlot3 {
  position: absolute;
  right: 14px;
  z-index: 3;
}

.avatarPileSlot4 {
  position: absolute;
  right: 0px;
  z-index: 4;
}

.tickBox {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  float: left;
  border: 1px;
}

.tickBoxDone {
  background-color: $secondary-blue;
}

.tickBoxTodo {
  background-color: $secondary-dark-grey;
}

input {
  width: 100%;
  font-size: 100%; 
  padding-top: 5px;
  padding-left: 10px;
  padding-bottom: 5px;

  border-radius: 3px;
  margin-bottom: 10px;
}

.habitCategoryCard {
  position: relative;
  height: 95px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;

  border-radius: 5px;
  border-bottom: 1px solid #E8EAED;
}

.habitCategoryCard:hover {
  @include hover-effect;
}

.habitCategoryCardPlaceholder {
  position: relative;
  height: 150px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: lightgray;  
  border-radius: 5px;
  overflow: hidden;
}

.habitCategoryCardName {
  position: absolute;
  overflow: hidden !important;
  text-overflow: elipsis;
  white-space: nowrap;
  width: 300px;
  top: 13px;
  left: 65px;
  color: $primary-black;
  font-size: 20px;
  font-weight: 600;
}

.habitCategoryCardDescription {
  position: absolute;
  width: 260px;
  top: 41px;
  left: 65px;
  color: #828996;
  font-size: 13px;
  font-weight: 400;
}


.habitCategoryCaret {
  position: absolute;
  top: 28px;
  right: 13px;
  color: $primary-black;
  font-size: 20px;
  font-weight: 300;
}

.habitCategoryCardSquircle {
  position: absolute;
  top: 15px;
  left: 5px;
}

.habitCategoryCardSquircleIcon {
  width: 42px;
  height: 42px;
  background-color: red;
}

.habitActivityCardContainer {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.habitActivityCard {
  @include card;

  position: relative;
  width: 100%;
  border: 1px solid #EFF3F8;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px #E0E3E9;
  z-index: 1;
  background: $primary-white;
}

.habitActivityCard:hover {
  @include card-hover;
}

.habitActivityCardCategoryLabel {
  position: relative;
  left: 15px;
  height: 18px;
  display:inline-block;

  margin-top: 15px;
  margin-bottom: 8px;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;

  height: 20px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  background: purple;
  color: $primary-white;
  border-radius: 18px;
}

.habitActivityCardHabitDescription {
  position: relative;
  padding-top: 5px;
  padding-bottom: 10px;
  left: 15px;
  width: 320px;
  font-size: 14px;
}

.habitActivityCardHabitName {
  position: relative;

  left: 15px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  width: 320px;
}

.habitActivityCardLine {
  position: relative;
  padding-top: 5px;
  padding-bottom: 2px;
  color:grey;
  font-size: 13px;
  width: 340px;
  line-height: 20px;
}

.habitActivityCardLineSerif {
  font-family: Georgia, serif;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  color:grey;
  font-size: 15px;
  width: 340px;
  line-height: 20px;
}

.habitActivityCardLineItalic {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  color:grey;
  font-size: 15px;
  width: 300px;
  line-height: 20px;
  font-style: italic;
}

.habitActivityCardProgressContainer {
  position: relative;
  height: 20px;
  width: 100%;
} 

.habitActivityCardProgressBar {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 220px;
  height: 1px;
  background: rgba(130, 137, 150, 0.15);
  border: 1px solid rgba(130, 137, 150, 0.15);
} 

.habitActivityCardProgressDone {
  position: absolute;
  top: 10px;
  left: 0px;
  height: 1px;
  background:#14223D;
  border: 1px solid #14223D;
} 

.habitActivityCardVisibility {
  position: absolute;
  bottom: 10px;
  right: 13px;
  color: #828996;
  font-size: 12px;
}

.habitActivityCardLineHighlighted {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #14223D;
  font-size: 14px;
}

.habitActivityCardInfoIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 14px;
  top: 15px;

  display: none;

  background-image: url('/icons/info-icon.png');
  background-size: cover;
}

.habitActivityCardTicked {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 10px;
  top: 12px;

  background-color: $secondary-blue;
  border-radius: 37px;
  border: none;

  background-image: url('/icons/tick-symbol.png');
  background-size: cover;
}

.habitActivityCardNotTicked {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 10px;
  top: 12px;

  -webkit-box-shadow:inset 0px 0px 0px 2px $secondary-blue;
  -moz-box-shadow:inset 0px 0px 0px 2px $secondary-blue;
  box-shadow:inset 0px 0px 0px 2px $secondary-blue;
  border-radius:37px;
}

.habitActivityCardNotTickedPast {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 10px;
  top: 10px;

  background-color: pink;

  -webkit-box-shadow:inset 0px 0px 0px 1px $primary-red;
  -moz-box-shadow:inset 0px 0px 0px 1px $primary-red;
  box-shadow:inset 0px 0px 0px 1px $primary-red;
  border-radius:37px;
}

.habitActivityCardNotTickedFuture {
  display: false
}


.habitActivityCardPlaceholder {
  @include card-placeholder;
  
  position: relative;
  height: 70px;
}



.habitActivityCardVariableHeight {
  padding-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid $secondary-light-grey;
}

.habitActivityEmptyStateCard {
  z-index: 1;
  position: relative;
  text-align: center;
  height: 240px;
  margin-top: 10px;
  margin: auto;
}

.habitActivityEmptyStateImage {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 280px;
  height: 210px;
}

.habitActivityEmptyStateMessage {
  position: absolute;
  font-size: 17px;
  color: black;
  top: 210px;
  width: 100%;
  text-align: center;
}

.statisticsEmptyState {
  text-align: center;
  width: 80%;
  margin: auto;
  height: 220px;
  background-size: cover;
  background-image: url('/illustration/statistics-empty-state.png');
}

.statisticsEmptyStateMessage {
  position: relative;
  top: 260px;
  font-size: 17px;
  color: $primary-red;
}


.sectionTitle {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  left: 0px;
  color: $secondary-dark-grey;
  font-size: 14px;
}

.activitySearchBox {
  border-width: 1px;
}

.activityNoActivity {
  text-align: center;
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  left: 0px;
  color: $secondary-dark-grey;
  font-size: 16px;
}

.activitySearchContainer {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.sidebarFeedbackPrompt {
  font-size: 12px;
  position: absolute;
  width: 100%;
  bottom: 50px;
  width: 100%;;
  height: 30px;
  text-align: center;
  color: $secondary-grey;
}

.homePageFeedbackPrompt {
  padding-top: 20px;
  font-size: 12px;
  width: 100%;
  color: $secondary-grey;
  margin: auto;
  text-align: center;
}  

.homePageSwitchButton {
  padding-top: 10px;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}  

.homePageFeedbackButton {
  padding-top: 10px;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}  

.sidebarInviteButton {
  position: absolute;
  width: 100%;
  margin: auto;
  text-align: center;
}

.sidebarFeedbackButton {
  position: absolute;
  width: 100%;
  bottom: 10px;
  margin: auto;
  text-align: center;
}

.habitOfTheDayPanel {
  position: absolute;
  bottom: 10px;
  margin: auto;
  width: 240px;
  height: 180px;
}

.habitOfTheDayPanelContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.habitOfTheDayPanelIllustration {
  position: absolute;
  bottom: 40px;
  left: 40px;
  width: 160px;
  height: 110px;
  
  border-radius: 10px;
}

.habitOfTheDayPanelTitle {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 240px;
  color: $secondary-dark-grey;
  font-size: 14px;
  text-align: center;
}

.habitOfTheDayPanelName {
  position: absolute;
  bottom: 10px;
  left: 0px;
  width: 240px;
  text-align: center;
  font-size: 18px;
}

.habitPopupPanel {
  position: relative;
  background-color: $primary-white;
  width: 320px;
}

.habitPopupPanelIcon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50px;
  left: 15px;
  border-radius: 2px;
}

.habitPopupHabitName {
  position: absolute;
  top: 54px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.habitPopupHabitJournal {
  position: absolute;
  top: 50px;
  left: 10px;
  font-size: 16px;
  width: 340px;
  height: 110px;
  padding: 10px;

  border: 1px solid $secondary-grey;
}

.habitPopupImageUploaderContainer {
  position: absolute;
  bottom: 70px;
  left: 0px;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid $secondary-light-grey;
}


.habitPopupImageUploader {
  position: relative;
  width: 100%;
  height: 100%;
}

.habitPopupImageIcon {
  position: absolute;
  width: 25px;
  height: 25px;
 // left: 10px;
  right: 10px;
 
  top: 6px;
}

.habitPopupImageLabel {
  position: absolute;
  width: 200px;
  height: 30px;
  left: 40px;
 
  top: 10px;
  font-size: 15px;
  color: $secondary-dark-grey
}

.habitPopupSkipButton {
  position: absolute;

  left: 0px;
  bottom: 10px;
}

.habitPopupDoneButton {
  position: absolute;
  right: 0px;
  bottom: 10px;
}

.teamChallengeHabitPopupDoneButton {
  position: absolute;
  left: 0px;
  bottom: 10px;
  width: 360px;
}

.feedback-popup-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
  height: 270px !important;
  padding: 0px !important;
  border: none !important;
}

.feedbackPopupInput {
  position: absolute;
  top: 60px;
  right: 10px;
  font-size: 16px;
  width: 340px;
  height: 140px;
  padding: 10px;
  border: 1px solid $secondary-grey;
}

.feedbackPopupSendButton {
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.feedbackPopupCancelButton {
  position: absolute;
  bottom: 10px;
  left: 5px;
}

.feedbackPopupOKButton {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 5px;
}

.feedbackPopupSendingFeedback {
  width: 100%;
  height: 100%;
  margin-top: 60px;
}

.feedbackPopupConfirmation {
  width: 200px;
  height: 100%;
  margin: auto;
  padding-top: 60px;
  text-align: center;
}

.habit-popup-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
  height: 230px !important;
  padding: 0px !important;
  border: none !important;
}

.habit-popup-with-image-uploader-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
  height: 270px !important;
  padding: 0px !important;
  border: none !important;
}

.end-habit-popup-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
  height: 310px !important;
  padding: 0px !important;
  border: none !important;
}

.endHabitPopupHabitJournal {
  position: absolute;
  top: 120px;
  left: 10px;
  font-size: 16px;
  width: 340px;
  height: 120px;
  padding: 10px;
  border: 1px solid $secondary-grey;
}

.endHabitPopupDoneButton {
  position: absolute;
  right: 0px;
  bottom: 10px;
  width: 100%;
}

.endHabitQuestionTable {
  padding: 5px;
}

.endHabitQuestion {
  font-size: 16px;
  padding-left: 5px;
}

.visibility-selector-popup-content {
  margin: auto;
  background: $primary-white;
  width: 320px !important;
  height: 180px !important;
  padding: 0px !important;
  border: none !important;
}

.goal-form-confirm-popup-content, .award-won-popup-content  {
  position: relative;
  margin: auto;
  background: $primary-white;
  width: 312px !important;
  height: 480px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 12px;
  -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

}

.ask-lumos-popup-content, .ask-lumos-popup-query-content {
  background: #FFF6E0 !important;
  margin: auto;
  width: 360px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 10px;
}

.ask-lumos-popup-query-content {
  height: 230px !important;
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}

.award-not-won-popup-content  {
  position: relative;
  margin: auto;
  background: $primary-white;
  width: 312px !important;
  height: 260px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 12px;
  -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.habitCreatedByAvatar {
  width: 20px;
  margin: auto;
 // margin-left: 25px;
}

.habitPracticedByAvatarPile {
  width: 40px;
  margin: auto;
}

.habitCreatedByCell, .habitPracticedByCell {
  width: 100%;
  height: 30px;
  text-align: center;
}

.habitFormConfirmPopup {
  position: relative;
  width: 100%;
  height: 100%;
}

.askLumosPopupHeader {
  padding-left: 10px;
  padding-top: 5px;
}

.askLumosPopupSpinner {
  padding-top: 20px;
}

.askLumosPopupTitle {
  font-size: 18px;
  font-style: italic;
  font-weight: 600px;
  padding-right: 20px;
}

.askLumosPopupBody {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  height: 220px;
  overflow-y: scroll;
}

.askLumusPopupPrompt {
  width: 280px;
  padding-top: 5px;
  height: 60px;
  font-size: 14px;
  padding-left: 5px;
  text-align: left;
}

.askLumosPopupQuery {
  position: absolute;
  top: 90px;
  left: 10px;
  font-size: 16px;
  width: 340px;
  height: 70px;
  padding: 10px;

  border: 1px solid $secondary-grey;
}

.askLumusPopupResponse {
  padding-bottom: 10px;
  white-space: pre-wrap;
  font-size: 14px;
  width: 260px;
}

.askLumosPopupResponseTable {
  vertical-align: top;
}

.askLumosPopupContinueButton {
  position: absolute;
  bottom: 10px;
  left: 5px;
  width: 350px;
}

.askLumosPopupCloseButton {
  padding-bottom: 10px;
}

.goalConfirmPopupAward {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 40px;
  left: 70px;
}

.goalConfirmPopupTips {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  
}

.goalConfirmPopupTipTitle {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
}

.goalConfirmPopupTipDescription {
  font-size: 18px;
  text-align: left;
  margin-bottom: 15px;
}

.goalConfirmPopupTipsHeader {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 15px;
}

.goalConfirmPopupMessage {
  position: absolute;
  width: 280px;
  text-align: center;;
  left: 10px;
  bottom: 60px;
  font-size: 21px;
  font-weight: 400;
}

.goalConfirmPopupOKButton {
  position: absolute;
  bottom: 20px;
  width: 310px;
}

.goalConfirmPopupSureButton {
  position: absolute;
  bottom: 20px;
  left: 10px;
}

.goalConfirmPopupNoThanksButton {
  position: absolute;
  bottom: 20px;
  right: 10px;
}


.awardWonPopupAward {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 40px;
  left: 70px;
}

.awardWonPopupMessage {
  position: absolute;
  width: 280px;
  text-align: center;;
  left: 10px;
  top: 260px;
  font-size: 21px;
  font-weight: 400;
}

.awardWonPopupComment {
  position: absolute;
  width: 280px;
  text-align: center;
  left: 10px;
  top: 350px;
  font-size: 13px;
  font-weight: 300;
  color: #6A798F;
}

.awardWonPopupOKButton {
  position: absolute;
  bottom: 20px;
  width: 310px;
}

.awardNotWonPopupMessage {
  position: absolute;
  width: 280px;
  text-align: center;;
  left: 10px;
  top: 30px;
  font-size: 21px;
  font-weight: 400;
}

.awardNotWonPopupComment {
  position: absolute;
  width: 280px;
  text-align: center;
  left: 10px;
  top: 60px;
  font-size: 13px;
  font-weight: 300;
  color: #6A798F;
}

.awardNotWonPopupOKButton {
  position: absolute;
  bottom: 20px;
  width: 310px;
}


.wellDonePopup {
  position: relative;
  width: 100%;
  height: 100%;
}

.wellDonePopupIllustration {
  position: absolute;
  width: 250px;
  top: 40px;
  left: 27px;
}

.wellDonePopupDoneButton {
  position: absolute;
  bottom: 20px;
  width: 310px;
}

.wellDonePopupRewardMessage {
  position: absolute;
  bottom: 20px;
  width: 310px;
}

.wellDonePopupWellDoneMessage {
  position: absolute;
  width: 280px;
  text-align: center;;
  left: 10px;
  top: 280px;
  font-size: 21px;
  font-weight: 400;
}

.wellDonePopupWellDoneComment {
  position: absolute;
  width: 280px;
  text-align: center;
  left: 10px;
  top: 350px;
  font-size: 13px;
  font-weight: 300;
  color: #6A798F;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.well-done-popup-content {
  position: relative;
  margin: auto;
  background: $primary-white;
  width: 312px !important;
  height: 480px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 12px;
  -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

}

.checkin-confirm-popup-content {
  position: relative;
  margin: auto;
  background: $primary-white;
  width: 312px !important;
  height: 540px !important;
  padding: 0px !important;
  border: none !important;
  border-radius: 12px;
  -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.checkinConfirmRibbon {
  position: absolute;
  top: 17px;
  left: 17px;
  width: 278px;
  height: 178px;
  color: $primary-white;
}

.checkinConfirmPinkStar {
  position: absolute;
  top: 30px;
  left: 112px;
  width: 88px;
  height: 84px;
}

.checkinConfirmCheckinsCounter {
  position: absolute;
  top: 51px;
  left: 0px;
  width: 100%;
  height: 42px;
  font-size: 36px;
  text-align: center;
  color: white;
}

.checkinConfirmHabitName {
  position: absolute;
  top: 128px;
  left: 0px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: $secondary-dark-grey
}

.checkinConfirmCheckinsDescription {
  position: absolute;
  top: 158px;
  left: 0px;
  width: 100%;
  height: 15px;
  font-size: 13px;
  text-align: center;
  color: $secondary-grey;
}

.checkinConfirmViewReportButton {
  position: absolute;
  top: 410px;
  left: 0px;
  height: 50px;
  width: 100%;
}


.checkinConfirmLumosContainer {
  position: absolute;

  background-color: #FFF6E0;
  border-radius: 15px;

  width: 285px;
  top: 280px;
  left: 10px;
  height: 120px;

}

.checkinConfirmLumosAvatar {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 23px;
}

.checkinConfirmLumosSpinner {
  position: absolute;
  top: 33px;
  left: 130px;
}

.checkinConfirmLumosResponse {
  position: absolute;
  top: 10px;
  left: 60px;
  width: 215px;
  font-size: 14px;
  line-height: 20px;
  height: 90px;
}


.checkinConfirmViewBackToHomeButton {
  position: absolute;
  top: 470px;
  left: 0px;
  height: 50px;
  width: 100%;
}

.checkinConfirmCheckinsByDayOfWeek {
  position: absolute;
  top: 210px;
  left: 0px;
  width: 100%;
  height: 100px;

  padding-left: 3px;
  padding-right: 3px;
}

.checkinConfirmCheckinsByDayOfWeekCellContainer {
  position: relative;
  margin: auto;
  text-align: center;
  margin: auto;
}

.checkinConfirmCheckinsByDayOfWeekReps {
  position: relative;
  width: 35px;
  height: 35px;
  background: #EEF4F8;
  border-radius: 17.5px;
  border: 1px solid #C1C1C1;
  font-size: 12px;
}

.checkinConfirmCheckinsByDayOfWeekSeparator {
  width: 3px;
  font-size: 1px;
}

.checkinConfirmCheckinsByDayOfWeekName {
  font-size: 10px;
  color: black;
}


.checkinConfirmWellDoneComment {
  position: absolute;
  width: 260px;
  text-align: center;
  left: 20px;
  top: 360px;
  font-size: 13px;
  font-weight: 300;
  color: #6A798F;
}

.checkinConfirmWellDoneMessage {
  position: absolute;
  width: 260px;
  text-align: center;;
  left: 20px;
  top: 290px;
  font-size: 21px;
  font-weight: 400;
}

.email-reminder-popup-content {
  margin: auto;
  background: $content-background-colour !important;
  width: 300px !important;
  padding: 0px !important;
  border: none !important;
}

.calendar-event-popup-content {
  margin: auto;
  background: $content-background-colour !important;
  width: 300px !important;
  height: 280px !important;
  padding: 0px !important;
  border: none !important;
}

.habit-loop-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 420px !important;
  padding: 0px !important;
  border: none !important;
}

.onboarding-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 450px !important;
  padding: 0px !important;
  border: none !important;
}


.onboardingPopupTitle {
  position: relative;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  left: 0px;
  text-align: center;
  color: $secondary-dark-grey;
  font-size: 14px;
}

.column {
  float: left;
  width: 50%;
  padding: 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}


.awardPortrait {
  position: relative;
  width: 167px;
  height: 209px;
}

.awardLandscape {
  position: relative;
  width: 300px;
  height: 120px;
  background: #EFF4F8;
}

.awardLandscape:hover {
  @include hover-effect();
}

.awardCategoryPortrait, .awardCategoryLandscape {
  position: relative;
  height: 18px;
 
  text-align: center;
  margin: auto;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;

  height: 20px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  background: purple;
  color: $primary-white;
}

.awardBannerPortrait, .awardBannerLandscape {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}

.award7PointStarPortrait, .award7PointStarLandscape {
  position: absolute;
  z-index: 1;
  top: 25px;

  font-size: 35px;
  font-weight: bold;
  width  : 100%;
}

.award7PointStarPortrait {
  left: 0px;
}

.award7PointStarLandscape {
  left: 10px;
}

.award5PointStarPortrait, .award5PointStarLandscape {
  position: absolute;
  z-index: 1;
  top: 25px;
  font-size: 35px;
  font-weight: bold;
  width  : 100%;
}

.award4PointStarPortrait, .award4PointStarLandscape {
  position: absolute;
  top: 35px;
  z-index: 1;
  font-size: 35px;
  font-weight: bold;
  width  : 100%;
}

.award7PointStarPortrait, .award5PointStarPortrait, .award4PointStarPortrait {
  left: 0px;
  text-align: center;

}

.award7PointStarLandscape {
  left: 10px;
}

.award5PointStarLandscape {
  left: 12px;
}

.award4PointStarLandscape {
  left: 20px;
}

.awardPercentagePortrait,.awardPercentageLandscape {
  position: absolute;
  top: 18px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 30px;
  width: 167px;

  color: white;
  fill: white;
  z-index: 1;
}

.awardPercentagePortrait {
  left: 0px;
}

.awardPercentageLandscape {
  width: 95px;
  left: 10px;
}

.awardNamePortrait, .awardNameLandscape {
  position: absolute;
 
  color: #14223D;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  z-index: 2;
}

.awardNamePortrait {
  top: 120px;
  width: 167px;
  text-align: center;
}

.awardNameLandscape {
  width: 200px;

  top: 30px;
  left: 100px;
  text-align: center;
}

.awardDaysPortrait, .awardDaysLandscape {
  position: absolute;
  z-index: 2;
  color: #6A798F;
  text-align: center;
}

.awardDaysPortrait {
  top: 175px;
  width: 167px;
}

.awardDaysLandscape {
  width: 200px;
  top: 85px;
  left: 100px;
  text-align: center;
}

.add-objective-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 245px !important;
  padding: 0px !important;
  border: none !important;
}

.editObjectivePopupAddButton {
  position: absolute;

  right: 0px;
  bottom: 7px;
}

.editObjectivePopupCancelButton {
  position: absolute;

  left: 10;
  bottom: 7px;
}

.editObjectivePopupName {
  margin-left: 10px;
  margin-right: 10px;
  width: 320px;
}

.editObjectivePopupColourPicker {
  margin-left: 10px;
}

.editObjectivePopupColourPicker input {
  display: none;
}

.invite-users-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 210px !important;
  padding: 0px !important;
  border: none !important;
}

.message-popup-content, .confirm-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 150px !important;
  padding: 0px !important;
  border: none !important;
}

.report-share-link-popup-content {
  margin: auto;
  background: $primary-white;
  width: 380px !important;
  height: 150px !important;
  padding: 0px !important;
  border: none !important;
}

.messagePopupMessage, .confirmPopupMessage {
  position: absolute;

  width: 300px;
  top: 55px;
  height: 30px;
  width: 100%;
  text-align: center;
  background-color: $primary-white;

  text-align: center;
  font-size: 16px;
  border-radius: 5px;
}

.messagePopupOKButton {
  position: absolute;

  left: 126px;
  bottom: 7px;
}

.emailReminderCard, .calendarEventCard {
  @include card;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

.emailReminderPopupCancelButton, .calendarEventPopupCancelButton {
  left: 10;
  float: left;

  padding-top: 10px;
  padding-bottom: 10px;
}

.emailReminderPopupOKButton, .calendarEventPopupOKButton {
  float: right;
  right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.emailReminderPopupDaySelector {
  margin-left: 10px;
  margin-right: 10px;
}

.habitLoopPopupOKButton, .onboardingPopupOKButton {
  position: absolute;
  width: 100%;
  bottom: 15px;
}

.onboardingPopupText {
  margin-left: 10px;
  margin-right: 10px;
}

.habitLoopPopupHabitImageContainer {
  width: 100%;
  margin: auto;
  margin-top: 10px;
  text-align: center;
}

.habitLoopPopupHabitImage {
  width: 200px;
  height: 104px;
}

.habitLoopPopupDescription {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}

.habitCreatedBy {
  text-align: center;
  font-size : 14px;
  padding-top : 10px;
  font-style: italic;
}

.onboardingPopupHabitImageContainer {
  width: 100%;
  margin: auto;
  text-align: center;
}

.onboardingPopupHabitImage {
  width: 200px;
  height: 104px;
}

.onboardingTitle {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 20px;
}

.onboardingQuestion {
  text-align: center;
  width: 100%;
  font-size: 18px;
  margin-bottom: 10px;
}

.onboardingContent {
  text-align: center;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 30px;
  color: $secondary-grey;
  padding-left: 50px;
  padding-right: 50px;
}

.onboardingObjectivesAndHabitsContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.objectivesAndHabitsImage {
  width: 360px;
}

.onboardingHabitLoopImageContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.onboardingHabitLoopImage {
  width: 200px;
}

.onboardingObjectivesAndSystemsImage {
  padding-bottom: 20px;
  width: 360px;
}

.confirmPopupCancelButton {
  position: absolute;

  left: 0px;
  top: 90px;
}

.confirmPopupOKButton {
  position: absolute;

  right: 0px;
  top: 90px;
}

.habit-popup-arrow .message-popup-arrow {
  color: $primary-white;
}

[role='tooltip'].habit-popup-content, [role='tooltip'].habit-popup-with-image-uploader-content, [role='tooltip'].habit-loop-popup-content, [role='tooltip'].onboarding-popup-content, [role='tooltip'].message-popup-content {
  width: 320px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.habit-popup-overlay, [role='tooltip'].habit-loop-popup-overlay, .message-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].habit-popup-overlay, [data-popup='tooltip'].habit-loop-popup-overlay, [data-popup='tooltip'].message-popup-overlay {
  background: transparent;
}

.calendar {  
  background: $primary-white;
  position: relative;
  margin-top: 10px;
  margin-right: 0px;
  margin-left: 0px;
  padding-bottom: 3px;

}

.calendarLoadingPlaceholder {
  @include card-placeholder;

  margin-top: 10px;
  position: relative;
  height: 254px;
}

.calendarLoadingPlaceholder::before, .objectiveCardPlaceholder::before, .challengeCardPlaceholder::before, .habitActivityCardPlaceholder::before, .habitCardPlaceholder::before, .habitCategoryCardPlaceholder::before, .journalCardPlaceholder::before, .onboardingHabitCardPlaceholder::before, .onboardingCategoriesPlaceholder::before, .teamActivityCardLoadingPlaceholder::before, .teamMemberPlaceholderCell::before {
  content: '';
  display: block;
  position: absolute;
  left: -150px;
  top: 0;
  height: 100%;
  width: 150px;
  background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
  animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@keyframes load {
  from {
      left: -150px;
  }
  to   {
      left: 100%;
  }
}

.calendarPrevWeek, .calendarPrevMonth{
  position: absolute;
  top: 28px;
  left: 0px;
  width: 40px;
  text-align: center;
  font-size: 22px
}

.calendarPrevWeek:hover, .calendarPrevMonth:hover {
  color: $primary-red-dark
}

.calendarNextWeek, .calendarNextMonth {
  position: absolute;
  top: 28px;
  right: 0px;
  width: 40px;
  text-align: center;
  font-size: 22px
}

.calendarNextWeek:hover, .calendarNextMonth:hover {
  color: $primary-red-dark
}

.calendarMain {
  top: 0px;
  left: 0px;
}

.calendarDatesRowCurrent {
  width: 100%;
}

.calendarDatesRowNotCurrent {
  width: 100%;
}

.calendarDayOfWeek {
  width: 45px;
  height: 26px;
  top: 15px;
  padding-top: 5px;
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
}

.calendarDateCell {
  position: relative;

  width: 45px;
  height: 45px;
  display: inline-block;
}

.calendarDate {
  width: 35px;
  height: 35px;
  font-size: 15px;
  margin: auto;
  padding-top: 7px;

  -webkit-box-shadow:inset 0px 0px 0px 1px $secondary-light-grey;
  -moz-box-shadow:inset 0px 0px 0px 1px $secondary-light-grey;
  box-shadow:inset 0px 0px 0px 1px $secondary-light-grey;
  border-radius: 25px;
}

.calendarDateHabitsNotDone {
  -webkit-box-shadow:inset 0px 0px 0px 1px $secondary-light-grey;
  -moz-box-shadow:inset 0px 0px 0px 1px $secondary-light-grey;
  box-shadow:inset 0px 0px 0px 1px $secondary-light-grey;
  border-radius: 25px;
}

.calendarDateHabitsNotDone:hover {
  background-color: $secondary-light-grey;
}


.calendarDateHabitsDone {
  -moz-border-radius: 25px; 
  -webkit-border-radius: 25px; 
  background-color: $secondary-blue;
  border-radius: 25px;
  color: $primary-white;

  -webkit-box-shadow:inset 0px 0px 0px 1px $secondary-blue;
  -moz-box-shadow:inset 0px 0px 0px 1px $secondary-blue;
  box-shadow:inset 0px 0px 0px 1px $secondary-blue;
  border-radius: 25px;
}

.calendarDateHabitsDone:hover {
  background-color: $secondary-blue;
}

.calendarDateNoHabits {

}

.calendarCurrentDateMarker {
  position: absolute;
  top: 37px;
  left: 15px;
  width: 15px;
  height: 4px;
  background-color: $primary-red;
}

.addHabitStartEndDateRow:after {
  content: "";
  display: table;
  clear: both;
}

.addHabitStartDateContainer, .addHabitEndDateContainer {
  float: left;
  width: 50%;
}

.addHabitButtonContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.addHabitFormDescription {
  width: 100%;
  font-size: 16px;
}

.timeSelectWrapper {
  width: 130px;
}

.habitCard {
  position: relative;
  height: 61px;

  margin-left: 10px;
  border-bottom: 1px solid #E8EAED;
}

.habitCard:hover {
  @include hover-effect()
}

.habitDescriptionCard {
  position: relative;
  margin-top: 10px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
} 

.habitGoalCard {
  @include card;
  position: relative;
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
} 

.habitCardWrapper {
  margin-left: 10px;
  margin-right: 10px;
}

.habitCardPlaceholder {
  @include card-placeholder;

  position: relative;
  height: 60px;
  margin-top: 10px;
}

.habitCardName {
  position: absolute;
  left: 0px;
  top: 12px;
  font-size: 16px;
  font-weight: 600;
}

.habitCardCreator {
  position: absolute;
  left: 0px;
  top: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #828996;
}

.habitCardAvatarPile {
  position: absolute;
  right: 27px;
  top: 17px;
}

.habitCardMaterialIconContainer {
  position: relative;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
} 

.habitCardPlus {
  position: absolute;

  width: 25px;
  height: 25px;
  right: 0px;
  top: 10px;

  color: $primary-white;
  border-radius: 18px;

  background-image: url('/icons/plus-symbol.png');
  background-size: cover;
}

.habitCardChevron {
  position: absolute;

  width: 25px;
  height: 25px;
  right: 0px;
  top: 17px;

  color: #14223D;
  font-size: 20px;
  font-weight: 300;
}

.popupHeaderChevron {
  position: absolute;

  width: 25px;
  height: 25px;
  right: 5px;
  top: -2px;

  color: white;
  font-size: 25px;
  font-weight: 300;
}


.habitCardPlusText {
  width: 25px;
  height: 25px;

  text-align: center; 
  line-height: 21px;

  font-size: 23px;
  font-style: bold;
} 

.addHabitFormHabitCard {
  position: relative;
  width: 100%;
  height: 42px;
  border: 1px solid lightgray;
}

.addHabitFormCategoryName {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  text-transform: uppercase; 
} 

.addHabitFormHabitName {
  position: absolute;
  height: 96px;
  left: 10px;
  top: 10px;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize; 
} 

.addHabitFormDayButtonSelected,.addHabitFormDayButtonNotSelected {
  width: 45px;
  height: 45px;
  text-align: center;
  color: $primary-white;
  vertical-align: middle;
  font-size: 11px;
  font-weight: 600;
  line-height: 45px;
  border-radius: 118px;
  text-transform: uppercase;
}

.addHabitFormDayButtonSelected {
  background-color: #14223D;
}

.addHabitFormDayButtonSelected:hover {
  @include hover-effect;
}



.addHabitFormDayButtonNotSelected {
  background-color: #F1F5F9;
  color: $primary-black;
  border: 1px solid #E2E8F0;
}

.addHabitFormDayButtonNotSelected:hover {
  @include hover-effect;
}

.addHabitFormRangeLine {
  position: relative;
  width: 100%;
}

.addHabitFormRangeContainer {
  width: 70%;
  display: inline-block;

}

.addHabitFormRangeContainer.input {
  color: black;
}

.addHabitFormRangeDescription {
  position: absolute;
  bottom: 5px;
  width: 30%;
  text-align: right;
  vertical-align: middle;
  display: inline-block;
  font-size: 16px;
  line-height: 38px;
}

.react-datepicker-wrapper {
  width: 90%;
}

.react-toggle--checked .react-toggle-track {
  background-color: $primary-red !important;
  border-color: $primary-red !important;
}

.react-toggle-thumb {
  border-color: $primary-red !important;
}

.addHabitForm {
  padding: 0% !important;
  margin-left: 10px;
  margin-right: 10px;
}

.addHabitFormDayButtonContainer {
  width: 14%;
  display: inline-block;
}

.addHabitFormDayButtonGroup {
  width: 100%;
  padding-bottom: 10px;
}

.addHabitFormName .addHabitFormGoal .addHabitFormNameReadonly, .addHabitFormOrder {
  width: 100%;
}

.addHabitFormNameReadonly {
  background-color: #e0e3e9;
  color: $secondary-dark-grey;
}

.onboardingHabitCard {
  @include card;

  position: relative;
  margin-top: 10px;
  height: 80px; 
}

.onboardingHabitCardAvatarPile {
  position: absolute;
  right: 20px;
  top: 25px;
}

.onboardingHabitCardChevron {
  position: absolute;
  right: 10px;
  top: 25px;
}

.onboardingHabitCardPlaceholder {
  @include card-placeholder();

  position: relative;
  margin-top: 10px;
  height: 80px; 
}

.onboardingCategoriesPlaceholder {
  @include card-placeholder();

  position: relative;
  margin-top: 10px;
  height: 160px; 
}

.onboardingHabitCard:hover {
  @include card-hover;
}



.onboardingHabitCardCategory {
  position: absolute;

  font-weight: 500;
  font-size: 12px;
  left: 20px;
  top: 18px;
  text-transform: uppercase;
}

.onboardingHabitCardName {
  position: absolute;

  font-weight: bold;
  font-size: 17px;
  left: 20px;
  top: 38px;
}

.challengeCard {
  @include card;

  position: relative;
  margin-top: 10px;
  height: 85px; 
}

.challengeCard:hover {
  @include card-hover;
}

.challengeCardPlaceholder {
  @include card-placeholder;

  position: relative;
  height: 80px;
 // margin-top: 10px;
}

.challengeCardHabitTickCell {
  position: relative;
  width: 15px;
}

.challengeCardHabitTickContainer {
  position: absolute;
  top: 32px;
  left: 15px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.challengeCardHabitTickImage {
  width: 20px;
  height: 20px;
}


.challengeCardCategory {
  position: absolute;

  display: none;

  font-weight: 500;
  font-size: 12px;
  left: 30px;
  top: 18px;
  text-transform: uppercase;
}

.challengeCardName {
  position: relative;
  height: 1.2em; 
  width: 320px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; 

  font-weight: bold;
  font-size: 18px;
  left: 20px;
  top: 18px;
}

.challengeCardPeriod {
  position: relative;
  top: 28px;
  left: 45px;
  font-size: 15px;
  width: 320px;
}

.challengeCardCog {
  position: absolute;
  top: 10px;
  right: 10px;
}

.challengeCardEye {
  position: absolute;
  top: 50px;
  right: 20px;
}


.challengeCardClockIcon {
  display: none;
  position: absolute;

  width: 20px;
  height: 20px;

  background-image: url('/icons/clock-symbol.png');
  background-size: cover;

  top: 50px;
  left: 20px;
}

.challengeCardCalendar {
  position: absolute;

  top: 49px;
  left: 18px;
  color: $secondary-dark-grey
}

.challengeCardVisibility {
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-size: 14px;  
}

.challengeCardDaysOfWeek {
  position: relative;
  top: 45px;
  left: 30px;
  width: 340px;
  height: 15px;
}

.challengeCardDayOfWeek, .challengeCardDayOfWeekNotSelected {
  display: inline-block;

  text-align: center;
  width: 43px;
  height: 20px;
  border-radius: 34px;
  font-size: 9px;
  font-weight: 600;

  margin-right: 4px;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
}

.challengeCardDayOfWeek {
  background-color: #14223D;
  color: $primary-white;
}

.challengeCardDayOfWeekNotSelected {
  background-color: #F1F5F9;
  color: $primary-black;
}

.challengeCardCompletionRate {
  position: absolute;
  top: 20px;
  right: 20px;
  font-weight: 400;
  font-size: 15px;
}

.challengeCardTick {
  position: absolute;
  top: 50px;
  left: 30px;
}

.challengeCardStartDate {
  position: absolute;

  left: 0px;
  top: 26px;
  font-weight: 500;
  font-size: 13px;
  color: #14223D;
  text-transform: uppercase;
}

.challengeCardEndDate {
  position: absolute;

  right: 0px;
  top: 26px;
  font-weight: 500;
  font-size: 13px;
  color: #14223D;
  text-transform: uppercase;

} 

.challengeCardTimeRangeIndicator {
  position: absolute;
  top: 20px;
  left: 0px;
  right: 0px;
  height: 2px;
  border-radius: 1px;
  background: #F1F5F9; 
}

.challengeProgressIndicatorContainer {
  position: absolute;
  top: 65px;
  left: 30px;
  right: 25px;
  height: 30px;
}

.challengeProgressIndicatorWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.challengeCardTimeRangeProgressBar {
  position: absolute;
  top: 20px;
  left: 0px;
  width: 250px;
  height: 2px;
  border-radius: 3px;
}

.challengeCardTimeRangeProgressBarBlob {
  position: absolute;
  top: 18px;
  height: 2px;
  border-radius: 6px;
  width: 6px;
  height: 6px;
}

.habitYouTube {
  padding-top: 10px;
  padding-bottom: 20px;

  height: 235px;
}

.habitForm {
  margin-left: 10px;
  margin-right: 10px;
}

.journalEmptyState {
  position: relative;
  margin-top: 10px;
  width: 380px;
  height: 250px;
  background-image: url('/illustration/journaling.jpg');
  background-size: cover;
  border-radius: 10px;
}

.journalEmptyStateMessage {
  position: absolute;
  width: 100%;
  text-align: center;;
  top: 270px;
  margin: auto;
  color: $primary-red;
}

.journalCard {
  @include card;

  position: relative;
}

.journalCardPlaceholder {
  @include card-placeholder;

  position: relative;
  margin-top: 10px;
  height: 40px;

}

.journalCardContent {
  position: relative;
  width: 100%;
  padding-left: 50px;
  top: 10px;
  padding-bottom: 30px;
  font-size: 14px;
  font-style: italic;
}

.journalCardTicked {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10px;
  top: 10px;

  background-color: #5ED8BC;
  border-radius: 15px;
  border: none;

  background-image: url('/icons/tick-symbol.png');
  background-size: cover;
}

.journalCardNotTicked {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  left: 10px;

  -webkit-box-shadow:inset 0px 0px 0px 2px #5ED8BC;
  -moz-box-shadow:inset 0px 0px 0px 2px #5ED8BC;
  box-shadow:inset 0px 0px 0px 2px #5ED8BC;
  border-radius: 25px;
}

.trendsCard {
  @include card;

  position: relative;

  box-sizing: border-box;

  height: 150px;
}

.trendsContainer {
  width: 33%;
  height: 100%;
  display: inline-block;
}

.trendsIndicator {
  position: relative;
  width: 80px;
  top: 20px;
  margin: auto;
}

.trendsBox {
  position: relative;
  top: 0px;
  width: 90px;
  height: 130px;
  margin: auto;
}

.trendsLabel {
  position: absolute;
  width: 90px;
  text-align: center;
  top: 110px;
  left: 0px;
  font-size: 12px;
  font-weight: bold;
}

.material-icons.md-light { 
  color: rgba(255, 255, 255, 1); 
}

.material-icons.md-30 { 
  font-size: 30px; 
}

.material-icons.md-28 { 
  font-size: 25px; 
}

.material-icons.md-20 { 
  font-size: 20px; 
}

.material-icons.md-15 { 
  font-size: 15px; 
}

.material-icons.md-10 { 
  font-size: 10px; 
}

.material-icons-outlined.md-30 { 
  font-size: 30px; 
}

.material-icons-outlined.md-28 { 
  font-size: 25px; 
}

.material-icons-outlined.md-20 { 
  font-size: 20px; 
}

.material-icons-outlined.md-15 { 
  font-size: 15px; 
}

.material-icons-outlined.md-10 { 
  font-size: 10px; 
}


.circleMembersAndHabitContainer {
  position: relative;
  width: 100%;
  height: 80px;
  padding-bottom: 5px;
}

.circleMembersContainer {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100px;
  height: 80px;
  display: inline-block;
}

.circleMembersCard {
  @include card;
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 5px !important;
}

.circleMembersCard:hover {
  @include card-hover;
}

.circleMembersCardInviteButton {
  float: right;
}

.circleHabitContainer {
  position: absolute;
  top: 0px;
  left: 120px;
  right: 0px;

  height: 80px;
  display: inline-block;
}

.circleHabitNameCard {
  @include card;
  position: relative;
  display: inline-block;
  height: 40px;
}

.circleHabitIcon {
  position: absolute;
  left: 7px;
  top: 7px;
  height: 25px;
  width: 25px;
  padding-left: 2px;
  padding-top: 2px;
  line-height: 20px;
  border-radius: 2px;
  color: $primary-white;
}

.circleHabitName {
  position: absolute;
  left: 40px;
  top: 10px;
  height: 20px;
  line-height: 20px;
}

.circleHabitStatsCount {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  line-height: 20px;
}

.circleHabitNameTriangle {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 20px;
  color: $secondary-grey;
  line-height: 20px;
}

.circleTimesDoneCard {
  @include card;
  padding: 0px;
  position: relative;
  display: inline-block;
}

.circleTimesDoneChartLegend {
  position: absolute;
  left: 10px;
  width: 30px;
  float: left;
  margin-top: 13px;
}

.circleTimesDoneChartLegendAvatar {
  height: 32px;
  padding-bottom: 5px;
}

.circleTimesDoneChartBars {
  position: absolute;
  left: 30px;
  right: 10px;
  float: right;
}

.circleHabitNameSelector {
  @include card;
  position: relative;
  display: inline-block;
  height: 40px;
  padding: 5px !important;
}

.circleHabitNameSelector:hover {
  @include card-hover
}

.circleHabitDropdownItem {
  @include card;
  position: relative;
  display: inline-block;
  height: 40px;
  margin-bottom: 0px !important;
}

.circleHabitDropdownItem:hover {
  background-color: $secondary-light-grey;
}

.circleMembersCard > .sb-avatar {
  margin-top: 2px;
  margin-left: 1px;
  margin-right: 3px;
}

.habit-name-popup-content {
  position: absolute;
  padding: 0px !important;
  left: 0px;
  top: 0px;
  width: 380px !important;
  max-height: 300px;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.habitNamePopupItems {
  overflow: auto;
  max-height: 270px;
}

.habitDropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.onboardingInstructions {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.onboardingSeparator {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
}

.inviteUsersPopupDescription {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 300px;
  font-size: 16px;
  font-weight: bold;
}

.inviteUsersPopupCancelButton {
  position: absolute;
  left: 0px;
  top: 150px;
}

.inviteUsersPopupSendButton {
  position: absolute;
  right: 0px;
  top: 150px;
}

.inviteUsersPopupEmailInput {
  position: absolute;
  top: 50px;
  left: 10px;
  width: 320px;
  height: 90px !important;
}

.inviteUserPopupMessage {
  position: absolute;
  top: 40px;
  width: 320px;
  left: 10px;
}

.editCategoriesButton {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  background-color: $secondary-dark-grey;
  color: $primary-white;
}

.editCategoryVisibilityLine, .editHabitVisibilityLine {
  position: relative;
  height: 40px;
  width: 100%;
}

.editHabitButtonLine {
  padding-top: 10px;
}

.editCategoryVisibilityToggleContainer, .editHabitVisibilityToggleContainer {
  position: absolute;
  left: 0px;
  width: 60px;
}

.editCategoryVisibilityToggleMessage, .editHabitVisibilityToggleMessage {
  position: absolute;
  left: 60px;
  line-height: 22px;
}

.editCategoryButton, .editHabitButton {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  background-color: #696969;
  color: $primary-white;
}

.editCategoryForm {
  margin-left: 10px;
  margin-right: 10px;
}

.editHabitForm {
  margin-left: 10px;
  margin-right: 10px;
}


.categoryColourImageSection {
  width: 100%;
  height: 80px;
}

.categoryColourContainer {
  width: 50%;
  height: 80px;
  float: left;
}

.categoryColourSelector {
  position: relative;
  width: 150px;
  height: 110px;
  border-radius: 10px;
}

.categoryIconSelector {
  position: relative;
}

.categoryImageContainer {
  float: left;
  width: 100%;
  height: 80px;
}

.categoryParentCategory {
  width: 100%;
  font-size: 16px;
  padding: 0.25em 0.5em;
}

.react-colorful {
  margin: auto;
}

.colour-picker-popup-content {
  margin: auto;
  background-color: transparent;
  width: 200px !important;
  height: 200px !important;
  padding: 0px !important;
  border: none !important;
}

.team-members-popup-content {
  margin: auto;
  background-color: transparent;
  width: 350px !important;
  height: 425px !important;
  padding: 0px !important;
  border: none !important;
}

.activity-likes-popup-content {
  margin: auto;
  background-color: transparent;
  width: 350px !important;
  height: 285px !important;
  padding: 0px !important;
  border: none !important;
}

.activity-comment-popup-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
  height: 200px !important;
  padding: 0px !important;
  border: none !important;
}

.activityCommentPopupComment {
  position: absolute;
  top: 50px;
  left: 10px;
  font-size: 16px;
  width: 340px;
  height: 70px;
  padding: 10px;

  border: 1px solid $secondary-grey;
}

.activityCommentPopupCancelButton {
  position: absolute;

  left: 0px;
  top: 140px;
}

.activityCommentPopupOKButton {
  position: absolute;

  right: 0px;
  top: 140px;
}


.categoryImageSelector {
  position: relative;
  width: 150px;
  height: 110px;
  border-radius: 10px; 
}

.contentManagerWarning {
  font-weight: bold;
  margin: 10px;
  text-align: center;
}

.habitPopupImageUploaderIcon {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 30px;
 
}


.uploadImageProgressIndicator {
  position: absolute;
  width: 320px;
  left: 10px;
  top: 95px;
  height: 7px;
  border-radius: 3px;
  border:1px solid $secondary-grey; 
}

.uploadImageProgressBar {
  position: absolute;
  width: 320px;
  left: 10px;
  top: 95px;
  height: 7px;
  border-radius: 3px;
  border:1px solid $secondary-grey; 
  background-color: $secondary-blue;
}

.categoriesEditButtonsContainer {
  width: 100%;
  position: relative;
  height: 40px;
  margin-top: 10px;
}

.categoriesEditCategoryButton {
  position: absolute;
  left: 10px;

  line-height: 40px;

  width: 160px;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  color: $primary-white;
}

.categoriesEditHabitsButton {
  position: absolute;
  right: 10px;

  line-height: 40px;

  width: 160px;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  background-color: $primary-white;
  color: $primary-white;
}

.addHabitButton, .editHabitsButton {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  text-align: center;
  font-size: 18px;
  line-height: 40px;
  background-color: #696969;
  color: $primary-white;
}

.material-icon-picker {
  position: absolute;
  background: #fcfcfc;
  text-align: center;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  padding: 20px;
}

.material-icon-picker:focus {
  outline: none;
}

.material-icon-picker-prefix {
  top: .5rem;
}

.material-icon-picker .icons {
  max-width: 300px;
  max-height: 200px;
  overflow: scroll;
}

div.material-icon-picker input[type="text"] {
  width: 100%;
}

.material-icon-picker .material-icons {
  font-size: 30px;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  margin: 3px;
  transition: .2s;
}

.material-icon-picker .material-icons:hover {
  background: #ececec;
}

.editHabitIcon {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 5px;
  top: 0px;

  border-radius: 2px;
}

.editHabitIconLine {
  position: relative;
  width: 100%;
  height: 45px;
}

.w-md-editor-toolbar {
  width: 384px;
}

.habitPopupImageUploaderProgressContainer {
  position: absolute;
  bottom: 10px;
  height: 50px;
  width: 100%;
}

.habitPopupImageUploaderProgressBarBackground {
  position: absolute;
  bottom: 10px;
  height: 30px;
  width: 340px;
  margin-left: 10px;
  margin-right: 10px;
  background: $secondary-light-grey;
  border-radius: 15px;
}

.habitPopupImageUploaderProgressBar {
  position: absolute;
  bottom: 10px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
  background: $secondary-blue;
  border-radius: 15px;
}

.habitActivityCardImageContainer {
  position: relative;
  top: 5px;
  left: 0px;
  height: 50px;
  width: 300px;
  margin-bottom: 5px;

}

.habitActivityCardImage {
  float: left;
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid $secondary-light-grey;
}

.habitPopupImageUploaderImageContainer {
  position: absolute;
  top: 5px;
  left: 10px;
  height: 30px;
  width: 300px;
}

.habitPopupImageUploaderImage {
  float: left;
  object-fit: cover;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border: 1px solid $secondary-light-grey;
}

.editHabitMaterialIconContainer {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
}

.editHabitTapIconMessage {
  position: absolute;
  top: 10px;
  left: 60px;
}

.editHabitVisibilityToggleMessage {
  position: absolute;
  top: 0px;
  left: 60px;
}

.iconSearchGridContainer {
  display: grid;
  grid-template-columns: 80px 80px 80px 80px;
  grid-template-rows: 80px 80px 80px 80px 80px 80px 80px 80px;  
  column-gap: 5px;
  row-gap: 5px;

  width: 340px;
  height: 340px;
  overflow: scroll;
}

.iconSearchGridItem {
  place-self: center;
  width: 50x;
  height: 40px;
}

.icon-selector-popup-content {
  margin: auto;
  background: $primary-white;
  width: 340px !important;
  height: 420px !important;
  padding: 0px !important;
  border: none !important;
}

.iconSelectorIcon {
  position: relative;
  width: 40px;
  height: 40px;
  left: 0px;
  top: 0px;
  border-radius: 2px;
}

.iconSelectorIcon:hover {
  @include hover-effect;
}

.iconSelectorIconContainer {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
}


.buttonContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.resetMyPasswordLink {
  color: $primary-red;
}

.teamActivityCommentCard {
  position: relative;
  background: $primary-white;
}

.teamActivityCommentCardHeader {
  position: relative;
  width: 100%;
  height: 40px;
}

.teamActivityCommentCardAvatar {
  position: absolute;
  left: 20px;
  top: 10px;
}

.teamActivityCommentCardReply {
  position: absolute;
  right: 0px;
  bottom: 2px;
  line-height: 40px;
  vertical-align: middle;
  margin: auto;
  text-align: center;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;  
  z-index: 1;
}

.teamActivityCommentCardComment {
  position: relative;
  width: 100%;
  color: grey;
  font-weight: 500;
  font-size: 13px;
  font-style: italic;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 3px;
  padding-left: 70px;
  padding-right: 50px;
  z-index: 0;
}

.teamActivityCommentCardDate {
  position: absolute;
  right: 10px;
  top: 17px;
  text-align: right;
  font-size: 12px;
}

.teamActivityCommentCardDescription {
  position: absolute;
  color: black;
  left: 70px;
  top: 17px;
  font-weight: 500;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
}

.teamActivityCommentCardSeparator {
  width: 100%;
  height: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid $secondary-light-grey;
}

.teamActivityCard {
  background: $primary-white;
}

.teamActivityCardItemWithHabit {
  position: relative;
  width: 100%;
  height: 100px;
}


.teamActivityCardItemWithoutHabit {
  position: relative;
  width: 100%;
  height: 40px;
}

.teamActivityCardLoadingPlaceholder {  
  @include card-placeholder();
  margin-top: 10px;
  position: relative;
  width: 100%;  
  height: 112px;
}

.teamActivityCardItemJournal {
  position: relative;
  width: 100%;
  color: grey;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0px;
  overflow: hidden;
  margin-bottom: 8px;
  padding-left: 70px;
  padding-right: 25px;
  font-style: italic;
}

.teamActivityCardImageContainer {
  position: relative;
  top: 0px;
  left: 70px;
  height: 50px;
  width: 300px;
  margin-bottom: 10px;
}

.teamActivityCardImage {
  float: left;
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid $secondary-light-grey;
}


.teamActivityCardItemLikesAndComments {
  position: relative;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 8px;
  padding-left: 70px;
  color: grey;
  height: 15px;
}

.teamActivityCardItemLikeIcon {
  position: absolute;
  top: 0px;
  left: 70px;
}

.teamActivityCardItemLikes {
  position: absolute;
  top: 5px;
  left: 105px;
  font-size: 16px;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.teamActivityCardItemMessagesIcon {
  position: absolute;
  top: 2px;
  left: 210px;
  font-size: 21px !important;
}

.teamActivityCardItemMessages {
  position: absolute;
  top: 5px;
  left: 240px;
  font-size: 16px
}

.habitSummaryCardSmall {
  position: relative;
  width: 300px;
  background: #EFF4F8;
  height: 60px;
}

.habitSummaryCardSmall:hover {
  @include hover-effect;
} 

.habitSummaryCardLarge {
  position: relative;
  width: 372px;
  background: #EFF4F8;
  height: 60px;
}

.habitSummaryCardLarge:hover {
  @include hover-effect;
}

.habitSummaryCardCategory {
  position: absolute;
  left: 20px;
  top: 10px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  width: 280px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;

  color: $primary-white;

}

.habitSummaryCardDays {
  position: absolute;
  color: #7B39ED;
  right: 10px;
  top: 10px;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
}

.habitSummaryCardAward {
  position: absolute;
  right: 10px;
  top: 30px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.habitSummaryCardDivider {
  position: absolute;
  width: 3px;
  height: 41px;
  top: 10px;
  left: 10px;
}

.habitSummaryCardName {
  position: absolute;
  left: 20px;
  top: 30px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  width: 280px;
  height: 1.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
}

.goalReportHeader {
  position: relative;
  width: 360px;
  margin: auto;
  border-bottom: 0.5px solid $secondary-light-grey;
  text-align: center;
  padding-top: 10px;
}

.goalReportAvatar {
  top: 10px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  text-align: center;
}


.goalReportCategoryLabel {
  position: relative;
  top: 10px;
  height: 18px;
  display:inline-block;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;

  height: 20px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  background: purple;
  color: $primary-white;
  border-radius: 18px;
}

.goalReportHabitName {
  position: relative;
  padding-top: 24px;
  padding-bottom: 12px;
  width: 100%;
  text-align: center;
  font-size: 23px;
  font-weight: 600;
}

.goalReportChevron {
  position: absolute;
  right: 5px;
  top: 40px;
}

.goalReportChevronImage {
  width: 8px;

}

.goalReportStats {
  position: relative;
  width: 360px;
  height: 70px;
  border-bottom: 0.5px solid $secondary-light-grey;
  margin: 0 auto;
}

.goalReportCompletionRate {
  position: absolute;
  top: 15px;
  left: 0px;
  width: 120px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.downloadOnAppStore {
  position: relative;
  width: 100%;
  height: 40px;
  text-align: center;
}

.goalReportCompletionRateLabel {
  position: absolute;
  top: 45px;
  left: 0px;
  width: 120px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportCompletions {
  position: absolute;
  top: 15px;
  left: 120px;
  width: 120px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.goalReportCompletionsLabel {
  position: absolute;
  top: 45px;
  left: 120px;
  width: 120px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportBestStreak {
  position: absolute;
  top: 15px;
  left: 240px;
  width: 120px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.goalReportBestStreakLabel {
  position: absolute;
  top: 45px;
  left: 240px;
  width: 120px;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportCharts {
  position: relative;
  width: 360px;
  height: 210px;
  border-bottom: 0.5px solid $secondary-light-grey;
  margin: 0 auto;
}

.goalReportChartsLabel {
  position: absolute;
  top: 15px;
  width: 360px;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportDaysChart {
  position: absolute;
  top: 30px;
  left: 0px;
  width: 180px;
  height: 110px;
  padding-left: 10px;
  padding-right: 10px;
}

.goalReportTimesChart {
  position: absolute;
  top: 30px;
  left: 180px;
  width: 180px;
  height: 110px;
  padding-left: 10px;
  padding-right: 10px;
}

.goalReportWeeksChart {
  position: absolute;
  top: 30px;
  left: 0;
  width: 360px;
  height: 400px;
  padding-left: 10px;
  padding-right: 10px;
}

.goalReportCalendar {
  position: relative;
  width: 360px;
  border-bottom: 0.5px solid $secondary-light-grey;
  margin: 0 auto;
}

.goalReportCalendarLabel {
  position: relative;
  top: 10px;
  width: 360px;
  font-size: 12px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportCalendarGrid {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 360px;
}

.goalReportCalendarGridTable {
  width: 100%;
}

.goalReportCalendarGridRow {
  width: 100%;
}

.goalReportCalendarGridCell {
  width: 50%;
  text-align: center;
  vertical-align: top;
}

.goalReportCalendarGridCellLabel {
  width: 100%;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportCalendarGridCellCalendar {
  width: 100%;
}

.goalReportJournal {
  width: 360px;
  margin: 0 auto;
}

.goalReportJournalLabel {
  position: relative;
  padding-top: 10px;
  width: 360px;
  margin: auto;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.goalReportJournalTable {
  width: 340px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px;

}

.goalReportJournalDateCell, .goalReportJournalDateCellMissed {
  width: 15%;
  line-height: 12px;
  text-align: left;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  vertical-align: top;
  padding-top: 5px;
}

.goalReportJournalDateCellMissed {
  text-decoration: line-through;
}

.goalReportJournalDateCellEndJournal {
  width: 15%;
  line-height: 12px;
  text-align: left;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  vertical-align: top;
  padding-top: 5px;
}

.goalReportJournalRow {
  height: 15px;
}

.goalReportJournalEntryCell {
  width: 85%;
  text-align: left;
  vertical-align: top;
  font-size: 15px;
  line-height: 20px;
  font-style: italic;
}

.goalReportJournalEntryCellEndJournal {
  width: 85%;
  text-align: left;
  vertical-align: top;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  font-style: italic;
}


.goalReportJournalImageContainer {
  position: relative;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 280px;
  margin-bottom: 10px;
}

.goalReportJournalImage {
  float: left;
  object-fit: cover;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border: 1px solid $secondary-light-grey;
}

.miniCalendar {
  width: 160px;
  margin: auto;
}

.miniCalendarRow {
  width: 100%;
  height: 20px;
}

.miniCalendarCell {
  position: relative;
  width: 20px;
  font-size: 10px;
  text-align: center;
  font-weight: 600;
}

.miniCalendarDayToday {
  position: absolute;
  top: 20px;
  left: 6px;
  width: 8px;
  height: 2px;
  background-color: $primary-red-bright;
} 

.miniCalendarDayLabelCompleted {
  width: 18px;
  height: 18px;
  border: 2px $secondary-blue solid;
  border-radius: 18px;
  background: rgba(123,57,237,0.9);
  padding-top: 3px;
  background-image: url(/icons/tick-symbol.png);
  background-size: cover; 
}

.miniCalendarDayLabelNotCompleted {
  width: 18px;
  height: 18px;
  border: 1px red solid;
  border-radius: 18px;
  background: pink;
  padding-top: 3px;
}

.miniCalendarDayLabelHabitDay {
  width: 18px;
  height: 18px;
  border: 2px $secondary-blue solid;
  border-radius: 18px;
  background-color: white;
  padding-top: 3px;
}

.miniCalendarDayLabel {
  width: 18px;
  height: 18px;
  padding-top: 3px;
  border: 1px grey solid;
  border-radius: 18px;
  padding-top: 3px;
}

.miniCalendarHabitDayLabel {
  border: 1px solid grey;
  border-radius: 16px;
}

.teamActivityCardHabitSummaryCard {
  position: absolute;
  top: 34px;
  left: 70px;
  width: 300px;
  background: #EFF4F8;
  height: 60px;
}

.teamActivityCardItemShareIcon {
  display: none !important;
  position: absolute;
  top: 0px;
  right: 30px;
  font-size: 22px !important;
}

.teamActivityCardItemVisibilityIcon {
  position: absolute;
  top: 0px;
  right: 30px;
  font-size: 22px !important;
}

.teamActivityCardSeparator {
  width: 100%;
  height: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid $secondary-light-grey;
}

.teamActivityCardItemDescription {
  position: absolute;
  color: black;
  left: 70px;
  top: 10px;
  font-weight: 500;
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
}

.teamActivityCardAwardItem {
  position: relative;
  width: 100%;
  height: 162px;
}

.teamActivityCardItemAvatar {
  position: absolute;
  left: 20px;
  top: 10px;
  width: 30px;
  height: 30px;
}

.teamActivityCardItemSquircle {
  position: absolute;
  left: 40px;
  top: 30px;
}

.teamActivityCardItemAward {
  position: absolute;
  left: 70px;
  top: 34px;
}

.teamActivityCardItemDate {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: right;
  font-size: 12px;
}

.teamMemberProfilePanel {
  position: relative;
  height: 120px;
  margin-top: 10px;
}

.teamMemberProfilePanelAvatar {
  position: absolute;
  top: 0px;
  left: 10px;
}

.teamMemberProfilePanelName {
  position: absolute;
  top: 20px;
  left: 130px;
  font-size: 22px;
  font-weight: 600;
  color: $primary-black;
}

.teamMemberProfilePanelEmail {
  position: absolute;
  top: 55px;
  left: 130px;
  font-size: 17px;
  color: $secondary-dark-grey;
}

.teamMemberProfilePanelPoints {
  position: absolute;
  top: 55px;
  left: 130px;
  font-size: 17px;
  color: $secondary-grey;
}

.teamMembersPopupContainer {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: $primary-white;
}

.teamMembersPopupRow {
  position: relative;
  width: 100%;
  background: $primary-white;
  height: 50px;
  border-bottom: 1px solid #E8EAED;
}

.addSharedHabitChallengeText {
  margin-top: 10px;
  margin-bottom: 20px;
}

.addSharedHabitChallengerName {
  color: $secondary-blue;
  font-weight: 600;
}

.spinnerPanel {
  width: 100%;
  height: 300px;
  text-align: center;
}

.teamMembersPopupRow:hover {
  @include hover-effect;
}

.teamMembersPopupRowAvatar {
  position: absolute;
  top: 10px;
  left: 10px;
}

.teamMembersPopupRowName {
  position: absolute;
  top: 0px;
  line-height: 50px;
  left: 50px;
}

.teamMemberCell {
  position: relative;
  width: 100%;
  background: $primary-white;
  height: 50px;
  border-bottom: 1px solid #E8EAED;
}

.teamMemberCell:hover {
  @include hover-effect();
}


.teamMemberPlaceholderCell {
  position: relative;
  height: 50px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: lightgray;  

  border-radius: 5px;
  overflow: hidden;
}

.teamMemberCellAvatar {
  position: absolute;
  top: 10px;
  left: 10px;
}

.teamMemberCellName {
  position: absolute;
  top: 8px;
  left: 55px;
  font-size: 16px;
  font-weight: 600;
}

.teamMemberCellHabits {
  position: absolute;
  top: 28px;
  left: 55px;
  font-size: 12px;
}

.teamMemberCellAdmin {
  position: absolute;
  top: 10px;
  right: 10px;
}

.teamMemberCellPoints {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 14px;
}


.levelsListCell {
  position: relative;
  width: 100%;
  background: $primary-white;
  height: 50px;
  border-bottom: 1px solid #E8EAED;
}

.levelsListCellLevel {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  left: 10px;
  font-size: 16px;
  padding-top: 5px;
  padding-left: 10px;
  border-radius: 12px;
  background-color: $secondary-light-grey;
}

.levelsListCellName {
  position: absolute;
  top: 14px;
  left: 55px;
  font-size: 16px;
  font-weight: 600;
}

.levelsListCellPoints {
  position: absolute;
  top: 15px;
  right: 50px;
  font-size: 16px;
  font-weight: 400;
}



.teamCell {
  position: relative;
  width: 100%;
  background: $primary-white;
  height: 50px;
  border-bottom: 1px solid #E8EAED;
}

.teamCell:hover {
  @include card-hover;
}

.teamCellName {
  position: absolute;
  top: 8px;
  left: 50px;
  font-size: 16px;
  font-weight: 600;
}

.teamCellAdmin {
  position: absolute;
  top: 26px;
  left: 50px;
  font-size: 12px;
  color: $secondary-grey;
}

.teamCellNumber {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: $primary-red;
  color: $primary-white;
  border-radius: 30px;
}

.welcomeTitle {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.welcomeDescription {
  text-align: center;
  font-size: 16px;
}

.quotePanel {
  position: relative;
  width: 100%;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.quotePanelQuote {
  position: relative;
  padding-top: 30px;

  font-weight: 400;
  font-size: 25px;
  text-align: center;
  font-family: "Georgia";
}

.quotePanelAuthor {
  padding-top: 30px;
  padding-bottom: 80px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  font-family: "Georgia";
}

.challengeTeammatesMessage {
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: 600;
}

.challengeTeammatesTeammatesSelection {
  position: relative;
  top: 0px;
  height: 30px;
}

.challengeTeammatesToggle {
  position: absolute;
  left: 0px;
  top: 0px;
}

.challengeTeammatesToggleLabel {
  position: absolute;
  left: 60px;
  top: 3px;
  font-size: 14px;
}

.wheelOfWorkContainer {
  margin-left: 30px;
  margin-right: 30px;
}

.wheelOfWorkCategory {
  text-align: center;
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 20px;
}

.wheelOfWorkSliderContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.wheelOfWorkSliderLabels {
  position: relative;
  width: 100%;
  height: 50px;
}

.wheelOfWorkSliderLabelsLeft {
  position: absolute;
  top: 30px;
  left: 15px;
  font-size: 12px;
  color: $secondary-grey;
}

.wheelOfWorkSliderLabelsRight {
  position: absolute;
  top: 30px;
  right: 15px;
  font-size: 12px;
  color: $secondary-grey;
}

.objectiveNameContainer {
  width: 100%;
  position: relative;
  margin: auto;
  height: 30px;
  text-align: center;
}

.objectiveCard {
  @include card;

  position: relative;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.objectiveCardPlaceholder {
  @include card-placeholder;
  
  position: relative;
  height: 140px;
}

.objectiveCardName {
  position: relative;
  padding-left: 15px;
  top: 0px;
}

.objectiveCardHabits {
  position: relative;
  font-weight: 300;
  font-size: 16px;
  margin-left: 15px;
  margin-right: 15px;
}

.objectiveCardHabit {
  width: 100%;
  margin-top: 5px;
}

.objectiveCardNoHabits {
  position: relative;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 20px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
}

.objectiveCardHabitName {
  width: 300px;
  padding-left: 7px;
  font-size: 16px;
  font-weight: 700;
}

.objectiveCardHabitCompletion {
  position: absolute;
  right: 0px;
  font-size: 15px;
}

.objectiveCardHabitTickCell {
  position: relative;
  width: 15px;
}

.objectiveCardHabitTickContainer {
  position: absolute;
  top: 3px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.objectiveCardHabitTickImage {
  width: 15px;
  height: 15px;
}

.objectiveCardObjectiveName {
  position: relative;
  height: 18px;
  display:inline-block;

  padding-left: 00px;
  padding-right: 0px;
  padding-top: 4px;

  height: 20px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: $primary-white;
  border-radius: 18px;
}



.verticalCenter {
    position: absolute;
    top: 40%;
    margin: auto;
    width: 100%;
}

.goalReportCenter {
  position: relative;
  top: 200px;
  margin: auto;
  width: 100%;
}

.habitFormEmailRemindersCard, .habitFormCalendarEventsCard {
  @include card;
}

.profileLevelCard {
  @include card;
  position: relative;
  height: 85px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.profileLevelCardContainer {
  margin-left: 15px;
  margin-right: 15px;
}

.profileLevelCard:hover {
  @include card-hover;
}

.profileLevelProgressBorder {
  position: relative;
  top: 10px;
  border:1px solid #ccc; 
  background-color: #C4C4C4;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.profileLevelProgressBar,.profileLevelProgressBar:hover,.profileLevelProgressBar,.profileLevelProgressBar:hover {
  color:#000!important;
  background-color: $secondary-blue;
  border-radius: 5px;
  height: 8px 
}

.profileLevelName {
  position: relative;
  top: 10px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: $secondary-dark-grey;
  padding-bottom: 10px;
}

.profileLevelMin {
  position: absolute;
  bottom: 7px;
  left: 20px;
  font-size: 14px;
  color: $secondary-grey;
}

.profileLevelMax {
  position: absolute;
  bottom: 7px;
  right: 20px;
  font-size: 14px;
  color: $secondary-grey;
}

.profileLevelPoints {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 9px;
  border-radius: 5px;
  font-size: 14px;
  color: $secondary-grey;;
}

.profileLevelProgressContainer {
  position: relative;
  top: 5px;
}

.delete-account-popup-content {
  margin: auto;
  background: $primary-white;
  width: 360px !important;
  height: 350px !important;
  padding: 0px !important;
  border: none !important;
}

.image-carousel-popup-content {
  width: 80% !important;
}

.deleteAccountPopupMessage {
  margin-left: 10px;
  margin-right: 10px;
}

.deleteAccountPopupCancelButton {
  position: absolute;

  left: 0px;
  bottom: 20px;
}

.deleteAccountInputBox {
  margin-left: 10px;
  margin-right: 10px;
}

.deleteAccountPopupDeleteButton {
  position: absolute;

  right: 0px;
  bottom: 20px;
}
 
.reportShareLinkPopupLink {
  font-size: 12px;
}

.reportShareLinkPopupCopyURLButton {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.reportShareLinkPopupOKButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.calendarDateToday {
  font-weight: 700;
}

.dayOfChallengeText {
  width: 100%;
  margin: auto;
  font-size: 20px;
  font-weight: 600;
  text-align: center; 
  margin-top: 20px;

}

.dayOfChallengeProgressBorder {
  margin-top: 9px;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
  border:1px solid #ccc; 
  background-color: #C4C4C4;
  border-radius: 2px;
}

.dayOfChallengeProgressBar,.dayOfChallengeProgressBar:hover {
  color: #000 !important;
  background-color: $secondary-blue;
  border-radius: 2px;
  height: 3px 
}

.dayOfChallengeProgressBarBackground {
  margin-top: 5px;
  color: #000 !important;
  background-color: $secondary-grey;
  border-radius: 2px;
  height: 3px 
}

.checksPointsRankBar {
  margin-top: 40px;
  border: 1px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  height: 140px;
}

.checksPointsRanksCheckinsBox {
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;
  width: 100px;
  height: 100px;
}

.checksPointsRanksPointsBox {
  position: absolute;
  margin-top: 0px;
  margin-left: 100px;
  width: 100px;
  height: 100px;
}

.checksPointsRanksRankBox {
  position: absolute;
  margin-top: 0px;
  margin-left: 200px;
  width: 100px;
  height: 100px;
}

.checksPointsRankBarStat {
  position: relative;
  top: 10px;
  margin-left: 20px;
  width: 60px;
  height: 60px;
  border: 1px solid black;
  border-radius: 40px;;
  text-align: center;
  color: black;
  vertical-align: middle;
  line-height: 60px;
  font-size: 25px;
  font-weight: 600;
}

.checksPointsRankBarStatLabel {
  position: absolute;
  bottom: 5px;
  color: grey;
  text-align: center;
  font-size: 12px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}


.teamChallengeHabitCard {
  @include card;
  position: relative;

  margin-top: 10px;
  margin-bottom: 10px;

  width: 100%;
  z-index: 1;
  background: $primary-white;
  height: 75px;
  border-left: 5px solid $secondary-blue
}

.teamChallengeHabitCard:hover {
  @include card-hover;
}

.teamChallengeHabitCardName {
  position: absolute;
  left: 14px;
  top: 14px;
  font-size: 19px;
  font-weight: 600;
}

.teamChallengeHabitCardPoints {
  position: absolute;
  left: 14px;
  top: 43px;
  font-size: 16px;
  color: $secondary-grey
}

.teamChallengeHabitCardCheckins {
  position: absolute;
  right: 10px;
  top: 17px;
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
  line-height: 42px;
  text-align: center;
  background-color: $secondary-blue;
  font-size: 26px;
  font-weight: 500;
  color: white;
}

// @media only screen and (max-width: 767px) {
  .calendarDatesRowNotCurrent {
    display: none;
  }

  .calendarPrevMonth, .calendarNextMonth {
    display: none;
  }

  .calendarLoadingPlaceholder {
    height: 74px;
  }
  .habitOfTheDayPanel {
    display: none;
  } 
// }

/*
@media only screen and (min-width: 768px) {
  .calendarPrevWeek, .calendarNextWeek {
    display: none;
  }
  .habitOfTheDayPanel {
    display: none;
  } 
}
 */








